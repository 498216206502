<template>
  <div class="menuTree" :class="['menuTree', `menuTree_${level}`]">
    <template v-for="(item, index) in menuList">
      <el-submenu
        v-if="item.children && item.children.length > 0"
        :key="index"
        :index="item.id"
        :class="[
          `level_${level}`,
          {
            isEcho: showPathArr.includes(item.id),
          },
        ]"
      >
        <template slot="title">
          <!-- <i class="el-icon-location"></i> -->
          <img
            :src="item.source.split('|')[0]"
            v-if="level === 1"
            v-show="!showPathArr.includes(item.id)"
            alt=""
          />
          <img
            :src="item.source && item.source.split('|')[1] ? item.source.split('|')[1] : item.source.replace('.png', '-light.png')"
            v-if="level === 1"
            v-show="showPathArr.includes(item.id)"
            alt=""
          />
          <span>{{ item.name }}</span>
        </template>
        <menuTree
          :menuList="item.children"
          :level="level + 1"
          :showPathArr="showPathArr"
        />
      </el-submenu>
      <el-menu-item
        :class="{ isEcho: showPathArr.includes(item.id) }"
        v-else
        :index="item.id"
        :key="item.id"
        @click="toLink(item)"
      >
        <!-- <i class="el-icon-caret-right" style="opacity: 0"></i> -->
        <span class="contentTitle" slot="title">{{ item.name }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: "menuTree",
  props: {
    // 组件当前层级
    level: {
      type: [Number, String],
      default: 1,
    },
    // 需要加上样式的节点唯一id集合
    showPathArr: {
      type: Array,
      default() {
        return [];
      },
    },
    // 当前项数据
    menuList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isCollapse: false,
      // idType: ["level1Id", "level2Id", "level3Id"],
    };
  },
  methods: {
    AiHuChuLogin() {
      let params = {
        nick_name: "hc_callout",
        pwd: "c4a2f909370e50cb3c517924fae515ab",
      };
      const formData = new FormData();
      formData.append("nick_name", params.nick_name);
      formData.append("pwd", params.pwd);
      let url = "/webapi/login";
      if (process.env.NODE_ENV === "production") {
        url = "https://huacong.thinkerw.com/webapi/login";
      }
      this.$axios.post(url, formData).then((res) => {
        if (res.code === 200) {
          this.toChuNewPage(res);
        }
      });
    },
    toChuNewPage(loginInfo) {
      let src = "https://huacong.thinkerw.com";
      var popup = window.open(src, "_blank");
      if (popup) {
        setTimeout(() => {
          popup.postMessage(JSON.stringify(loginInfo), src);
        }, 500);
      }
    },
    AiZhishiLogin() {
      let params = {
        nick_name: "hc_callout",
        pwd: "c4a2f909370e50cb3c517924fae515ab",
      };
      const formData = new FormData();
      formData.append("nick_name", params.nick_name);
      formData.append("pwd", params.pwd);
      let url = "/apiTestOutboundAuto/sentence/v1/login";
      if (process.env.NODE_ENV === "production") {
        url =
          "https://huacongoutauto.thinkerw.com/apiTestOutboundAuto/sentence/v1/login";
      }
      this.$axios.post(url, formData).then((res) => {
        if (res.code === 200) {
          this.toZhishiNewPage(res);
        }
      });
    },
    toZhishiNewPage(loginInfo) {
      const iframe = document.createElement("iframe"); //设置iframe样式，使其不可见
      iframe.setAttribute(
        "style",
        "position:absolute;width:0px;height:0px;left:-500px;top:-500px;"
      );
      iframe.setAttribute("allow", "payment"); //将iframe地址改为目标系统
      iframe.src = "https://huacongoutauto.thinkerw.com/testOutboundAuto";
      document.body.append(iframe);
      iframe.onload = () => {
        //发送消息
        iframe.contentWindow.postMessage(
          loginInfo,
          "https://huacongoutauto.thinkerw.com/testOutboundAuto"
        );
        setTimeout(function () {
          iframe.remove();
        }, 5000); //新开页签，跳转到目标系统地址
        setTimeout(function () {
          window.open("https://huacongoutauto.thinkerw.com/testOutboundAuto");
        }, 0);
      };
    },
    AiHuRuLogin() {
      let params = {
        nick_name: "hc_callin",
        pwd: "557416a36e98a80bcc8451ce5d56ef7c",
      };
      const formData = new FormData();
      formData.append("nick_name", params.nick_name);
      formData.append("pwd", params.pwd);
      let url = "/CallInWebApp/login";
      if (process.env.NODE_ENV === "production") {
        url = "https://huaconginbound.thinkerw.com/CallInWebApp/login";
      }
      this.$axios.post(url, formData).then((res) => {
        if (res.code === 200) {
          this.toNewPage(res);
        }
      });
    },
    toNewPage(loginInfo) {
      const iframe = document.createElement("iframe"); //设置iframe样式，使其不可见
      iframe.setAttribute(
        "style",
        "position:absolute;width:0px;height:0px;left:-500px;top:-500px;"
      );
      iframe.setAttribute("allow", "payment"); //将iframe地址改为目标系统
      iframe.src = "https://huaconginbound.thinkerw.com/";
      document.body.append(iframe);
      iframe.onload = () => {
        //发送消息
        iframe.contentWindow.postMessage(
          loginInfo,
          "https://huaconginbound.thinkerw.com/"
        );
        setTimeout(function () {
          iframe.remove();
        }, 5000); //新开页签，跳转到目标系统地址
        setTimeout(function () {
          window.open("https://huaconginbound.thinkerw.com/");
        }, 0);
      };
    },
    toLink(item) {
      if (item.alias == "third-backend") {
        this.$router.push({
          name: "oIframe",
          query: {
            path: item.path,
            modelKey: item.modelKey,
            fromId: item.id,
          },
        });
      } else if (item.alias == "AI呼入管理") {
        this.AiHuRuLogin();
      } else if (item.alias == "AI呼出管理") {
        this.AiHuChuLogin();
      } else if (item.alias == "AI知识库管理") {
        this.AiZhishiLogin();
      } else {
        if (item.url) {
          this.$router.push({
            name: item.url,
          });
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.menuTree {
  padding-left: 15px;
  &.menuTree_1 {
    padding-left: 0;
  }
  .level_1 {
    ::v-deep > .el-submenu__title {
      padding: 0 0 0 40px !important;
      position: relative;
      > img {
        position: absolute;
        left: 10px;
        top: 18px;
        width: 20px;
        height: 20px;
      }
      &:hover {
        color: #409eff;
        background: #fff !important;
      }
    }
  }
  ::v-deep .el-submenu__title {
    padding: 0 0 0 30px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // border-radius: 4px;
    &:hover {
      color: #409eff;
      background-color: transparent;
    }
    i.el-submenu__icon-arrow {
      // display: none;
    }
  }
  ::v-deep .el-menu-item {
    width: 100%;
    min-width: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0 0 30px !important;
    &:hover {
      color: #409eff;
      background: transparent;
    }
    &:focus {
      background: transparent;
    }
    &.is-active {
      color: #303133;
    }
  }

  .isEcho {
    ::v-deep > .el-submenu__title {
      color: #409eff;
      font-weight: bold;
    }
  }
  .isEcho.level_1 {
    ::v-deep > .el-submenu__title {
      background-color: #fff;
    }
  }
  ::v-deep .el-menu-item {
    &.isEcho {
      color: #409eff;
      background: #f7f8fb;
      border-radius: 20px 0 0 20px;
      .contentTitle {
        position: relative;
        &::before {
          position: absolute;
          left: -15px;
          top: 5px;
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          background: #409eff;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
