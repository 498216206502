<template>
  <div class="hc-sideBar">
    <el-scrollbar class="scrollbar">
      <el-menu
        ref="elMenu"
        :collapse="!sidebar.opened"
        :default-openeds="defaultOpeneds"
      >
        <menuTree
          ref="menuTree"
          :menuList="menuList"
          :showPathArr="showPathArr"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import menuTree from "@/components/bussiness/menuTree/index.vue";

export default {
  name: "sideBar",
  components: { menuTree },
  data() {
    return {
      isCollapse: false,
      showPathArr: [],
      oList: [],
      levelList: [],
    };
  },
  computed: {
    defaultOpeneds() {
      return [...this.showPathArr];
    },
    ...mapState("app", [
      "sidebar",
      "menuList",
      "formatMenu",
      "flatMenu",
      "menuLimit",
    ]),
  },
  watch: {
    $route: {
      handler(val) {
        if (this.$route.meta.fromId == "第三方") {
          this.handleTeRouter();
        } else {
          this.handleRouter();
        }
        if (val.path.startsWith("/redirect/")) {
          return;
        }
        if (this.$route.meta.fromId == "第三方") {
          this.getTeBreadcrumb();
        } else {
          this.getBreadcrumb();
        }

        // const sideBarStr = sessionStorage.getItem("sideBar");
        // const sideBar = sideBarStr ? JSON.parse(sideBarStr) : {};
        // if (this.showPathArr.length === 0) {
        //   this.showPathArr = sideBar.pathKey || [];
        // }
        //  else if (this.showPathArr.length !== 0) {
        //   sessionStorage.setItem(
        //     "sideBar",
        //     JSON.stringify({
        //       ...sideBar,
        //       pathKey: this.showPathArr,
        //     })
        //   );
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$store.commit("app/setMenu", this.oList);
    this.getMenu();
  },
  beforeDestroy() {
    sessionStorage.removeItem("sideBar");
  },
  methods: {
    getTeBreadcrumb() {
      let fromId = this.$route.query.fromId;
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
      } else {
        // 如果meta.echo不存在，说明不是次级页面
        this.$clearBreadList();
      }

      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.id === fromId) {
          result = [menu, ...menu.parentArr]
            .reverse()
            .map((v) => ({ title: v.name, id: v.id }));
        }
      });

      const breadcrumbStr = sessionStorage.getItem("breadcrumb");
      const breadcrumb = breadcrumbStr ? JSON.parse(breadcrumbStr) : {};
      if (result.length > 0) {
        this.levelList = result;
        this.$store.commit("app/setLevelList", this.levelList);
      }
    },
    getBreadcrumb() {
      let curPath = this.$route.path;
      console.log(this.$route.meta, "this.$route");
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
        curPath = this.$route.meta.echo;
      } else {
        // 如果meta.echo不存在，说明不是次级页面
        this.$clearBreadList();
      }
      // 路由以 /home 为前缀
      if (/^\/home/.test(curPath)) {
        curPath = curPath.replace(/^\/home\//, "");
      } else {
        curPath = curPath.replace(/^\//, "");
      }
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.path === curPath) {
          result = [menu, ...menu.parentArr]
            .reverse()
            .map((v) => ({ title: v.name, id: v.id }));
        }
      });
      console.log(result, "result---");
      const breadcrumbStr = sessionStorage.getItem("breadcrumb");
      const breadcrumb = breadcrumbStr ? JSON.parse(breadcrumbStr) : {};
      if (result.length > 0) {
        // sessionStorage.setItem(
        //   "breadcrumb",
        //   JSON.stringify({
        //     old: breadcrumb.new || [],
        //     new: result,
        //   })
        // );
        this.levelList = result;
        this.$store.commit("app/setLevelList", this.levelList);
      }
    },
    handleTeRouter() {
      let fromId = this.$route.query.fromId;
      const flatMenu = this.flatMenu;
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.id === fromId) {
          result = [menu, ...menu.parentArr].reverse();
        }
      });
      if (result.length > 0) {
        this.showPathArr = result.map((v) => v.id);
      }
    },
    handleRouter() {
      let curPath = this.$route.path;
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
        curPath = this.$route.meta.echo;
      }
      if (/^\/home/.test(curPath)) {
        curPath = curPath.replace(/^\/home\//, "");
      } else {
        curPath = curPath.replace(/^\//, "");
      }
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.path === curPath) {
          result = [menu, ...menu.parentArr].reverse();
        }
      });
      if (result.length > 0) {
        this.showPathArr = result.map((v) => v.id);
      }
    },
    getMenu() {
      let params = {
        roleId: this.$store.state.app.userInfo.roleId,
        userId: this.$store.state.app.userInfo.userId,
      };
      let menuLimit = JSON.parse(sessionStorage.getItem("menuLimit"));
      this.$store.commit("app/setMenuLimit", menuLimit);
      let url = `/gateway/blade-system/menu/routes`;
      if (this.menuLimit.name && this.menuLimit.type) {
        url = `/gateway/blade-system/menu/routes?domain=${this.menuLimit.type}`;
      }
      this.$axios.post(url, params).then((res) => {
        if (res.code === 200) {
          this.oList = res.data;
          this.$store.commit("app/setMenu", this.oList);
          const formatMenu = this.addField(this.menuList);
          const flatMenu = this.getFlatArr(formatMenu);
          console.log(formatMenu, flatMenu, "======");
          this.$store.commit("app/setFormatMenu", formatMenu);
          this.$store.commit("app/setFlatMenu", flatMenu);
          if (this.$route.meta.fromId == "第三方") {
            this.handleTeRouter();
          } else {
            this.handleRouter();
          }
          if (this.$route.meta.fromId == "第三方") {
            this.getTeBreadcrumb();
          } else {
            this.getBreadcrumb();
          }
        } else {
        }
      });
    },
    // 为数组添加字段
    addField(a, o = []) {
      let deepArr = _.cloneDeep(a);
      deepArr.forEach((route) => {
        // 1. 用于追溯该对象所有的上层对象
        route.parentArr = o;
        // 2. 用于判断该节点是否需要变成被选中状态
        route.isSelect = false;
        // 3. 递归设置
        if (route.children) {
          route.children = this.addField(route.children, [route, ...o]);
        }
      });
      return deepArr;
    },
    // 获取扁平化数组
    getFlatArr(a) {
      let deepArr = _.cloneDeep(a);
      let resultArr = [];
      let flatMethos = (childs) => {
        childs.forEach((child) => {
          resultArr.push(child);
          if (child.children) {
            flatMethos(child.children);
          }
        });
      };
      flatMethos(deepArr);
      return resultArr;
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/less/global.less";
.hc-sideBar {
  width: 100%;
  height: 100%;
  // overflow: auto;
  // overflow-x: hidden;
  padding: 10px 0px 10px 12px;
  box-sizing: border-box;
  background: #fff;
  .scrollbar {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
      margin: 0 !important;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0px; /* 纵向滚动条*/
        height: 0px; /* 横向滚动条 */
        background-color: #fff;
      }

      /*定义滚动条轨道 内阴影*/
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: #ccc;
      }

      /*定义滑块 内阴影*/
      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
      }
    }
  }
  ::v-deep .el-menu {
    border: 0;
    width: 100%;
  }
}
</style>
