<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        v-for="item in [...levelList, ...this.breadList]"
        :key="item.id"
      >
        <span>{{ `${item.title}` }}</span>
        <!-- <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
// import pathToRegexp from 'path-to-regexp'
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("app", [
      "levelList",
      "sidebar",
      "menuList",
      "formatMenu",
      "flatMenu",
      "breadList",
    ]),
  },
  watch: {
    $route(route) {
      // 如果你要进入重定向页面，请不要更新面包屑
      // this.getBreadcrumb();
    },
  },
  created() {
    // this.getBreadcrumb();
  },
  methods: {
    getBreadcrumb() {
      let curPath = this.$route.path;
      console.log(this.$route.meta, "this.$route");
      const flatMenu = this.flatMenu;
      if (this.$route.meta && this.$route.meta.echo) {
        curPath = this.$route.meta.echo;
      } else {
        // 如果meta.echo不存在，说明不是次级页面
        this.$clearBreadList();
      }
      // 路由以 /home 为前缀
      if (/^\/home/.test(curPath)) {
        curPath = curPath.replace(/^\/home\//, "");
      } else {
        curPath = curPath.replace(/^\//, "");
      }
      let result = [];
      flatMenu.forEach((menu) => {
        if (menu.path === curPath) {
          result = [menu, ...menu.parentArr]
            .reverse()
            .map((v) => ({ title: v.name, id: v.id }));
        }
      });
      console.log(result, "result---");
      const breadcrumbStr = sessionStorage.getItem("breadcrumb");
      const breadcrumb = breadcrumbStr ? JSON.parse(breadcrumbStr) : {};
      if (result.length > 0) {
        // sessionStorage.setItem(
        //   "breadcrumb",
        //   JSON.stringify({
        //     old: breadcrumb.new || [],
        //     new: result,
        //   })
        // );
        this.levelList = result;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
